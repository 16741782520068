import mnml from '@dryan-llc/mnml.js';

(() => {
  if (window.customElements.get('pr-alert')) return;

  class PrideraiserAlert extends HTMLElement {
    get level(): string | null {
      return this.getAttribute('level') || null;
    }
    set level(value: string | null) {
      if (value) {
        this.setAttribute('level', value || '');
      } else {
        this.removeAttribute('level');
      }
      if (this.querySelector('svg.keep-icon')) {
        return;
      }
      this.querySelector('svg')?.remove();
      let icon: string | null = null;
      if (this.layout === 'push-subscription') {
        icon = 'bell';
      } else {
        switch (value) {
          case 'success':
            icon = 'circle-check';
            break;
          case 'warning':
            icon = 'triangle-exclamation';
            break;
          case 'error':
            icon = 'octagon-exclamation';
            break;
          case 'info':
            icon = 'circle-info';
            break;
        }
      }
      if (icon) {
        const svg = mnml.html`
          <svg viewBox="0 0 512 512" aria-hidden="true" class="icon icon--${icon} icon--${icon}--regular">
            <use href="#icon--${icon}--regular"></use>
          </svg>
        `;
        this.prepend(svg);
      }
    }

    get layout(): string | null {
      return this.getAttribute('layout') || null;
    }
    set layout(value: string | null) {
      if (value) {
        this.setAttribute('layout', value || '');
      } else {
        this.removeAttribute('layout');
      }
    }

    connectedCallback() {
      if (!this.role) {
        this.setAttribute('role', 'alert');
      }
      if (!this.querySelector('.text,.title')) {
        this.replaceChildren(mnml.html`<p class="text">${this.innerHTML}</p>`);
      }
      this.level = this.getAttribute('level');
      if (this.level?.includes('push-subscription')) {
        this.level = this.level.replace('push-subscription', '').trim();
        this.layout = 'push-subscription';
      }
    }
  }

  window.customElements.define('pr-alert', PrideraiserAlert);
})();
